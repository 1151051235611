<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-lifebuoy</v-icon>
          <h1 class="darkGrey--text">Support</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Support
            </span>
          </v-tooltip>
        </span>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'

export default Vue.extend({
  name: 'Support',
  components: {
    Portal
  },
  data: () => ({
    breadcrumbsItems: [
      {
        text: 'Support',
        disabled: true,
        href: ''
      }
    ]
  })
})
</script>
